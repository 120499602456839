<script>
	export let today = new Date()
	export let currentYear = today.getFullYear()
	export let currentMonth = (today.getMonth() + 1)
	export let currentDay = today.getDate()
	// used to calculate where today is as a % from the whole year, for the background fill
	export let currentDayOfYear = Math.floor((today - new Date(today.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)

	export let locale = 'en'

	// these form the sections in each year, regardless if there are milestones or not
	export let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	export let monthsAR = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
	// actual data from the API
	export let years = []

	// width of the div of a single year in px
	export let yearWidth
	// detects the left scroll position of the timeline
	export let currentScroll = 0
	// detects the index of the year in view from the "years" array,
	// so we can show previous and next buttons appropriately 
	export let yearInView = 0

	export let activeMilestone
	export let activeMilestoneMonths = []

	export let arApi = 'https://nabidh.ae/cms/events-time-line-arabics?isActive=true&_sort=year:ASC'
	export let enApi = 'https://nabidh.ae/cms/events-time-lines?isActive=true&_sort=year:ASC'
	
	initializeTimeline()

	function initializeTimeline() {
		getPortalLang()
		if (locale == 'ar') getData(arApi)
		else getData(enApi)
	}

	function getPortalLang() {
		const savedLang = localStorage.getItem('portalLang')
		if (savedLang != null && savedLang == 'ar') locale = 'ar'
		else locale = 'en'
	}

	function getData(api) {
		// fetch timeline data and assign it to "years" array
		fetch(api)
		.then(async (response) => {
			const res = await response.json()
			console.log(res)
			years = res
			calculateOriginalActiveMilestone()
			
			// after 1 second calculate all the widths so we can fill the background and scroll to center today's date
			setTimeout(() => {
				calculateWidths()
				goToday()
			}, 2000);
		})
		.catch((error) => {
			console.log(error)
		})
	}

	function calculateOriginalActiveMilestone() {
		// go current year > current month
		const currentYearIndex = years.map(e => parseInt(e.year, 10)).indexOf(currentYear);

		let newEventsArray = []

		for (let index = 0; index < years.length; index++) {
			const year = years[index];
			year.eventList.forEach(event => {
				newEventsArray.push({
					id: event.id,
					month: months.indexOf(event.month) + 1,
					year: year.year
				})
			})
		}

		for (let index = 0; index < newEventsArray.length; index++) {
			const event = newEventsArray[index];
			if (event.year == currentYear && event.month == currentMonth) activeMilestoneMonths.push(event.id)
			else if (event.year == currentYear && event.month == (currentMonth + 1)) activeMilestoneMonths.push(event.id)
			else if (event.year == currentYear && event.month == (currentMonth - 1)) activeMilestoneMonths.push(event.id)
		}

		activeMilestone = activeMilestoneMonths[0]
	}

	// defines logic for whether a milestone is complete/green or incomplete/grey
	function isMilestoneComplete(milestone, year) {
		// else green it at the start of the next month
		const milestoneMonth = months.indexOf(milestone.month) + 1
		
		if (year < currentYear) return true
		else if (year == currentYear && milestoneMonth < currentMonth) return true
		// if the milestone HAS a day, green it based on today
		else if (milestone.hasOwnProperty('day')) {
			// if this milestone in the current month
			if (year == currentYear && milestoneMonth == currentMonth) {
				// if today already passed
				if (milestone.day != null && milestone.day != undefined && milestone.day <= currentDay) return true
				else return false
			}
		}
		else return false
	}

	function isMilestoneOpen(milestone) {
		if (milestone.id == activeMilestone) return true
		else return false
	}

	function bgFillWidth(year) {
		let fillWidth = '0%'

		if (year.year == currentYear) fillWidth = ((currentDayOfYear/365) * 100) + '%'
		else if (year.year < currentYear) fillWidth = '100%'
		else fillWidth = '0%'

		return fillWidth
	}

	// this is used to calcualte the width of each year div in px,
	// so we can use it to fill the background progress, and scroll the content
	function calculateWidths() {
		let element = document.getElementById(years[0].year)
		if (element != null) yearWidth = element.offsetWidth
	}
	
	// what to do everytime we scroll:
	// 1. recalculate widths to make sure all measurements are up to date
	// 2. update "currentScroll" value so we keep track of it
	// 3. update the index of the current year
	function handleScroll() {
		calculateWidths()
		const element = document.getElementById('timeline-body')
		if (element != null) currentScroll = element.scrollLeft

		years.forEach((el, index) => {
			
			if (currentScroll <= -((yearWidth * index) * 0.75) && locale == 'ar') yearInView = index
			else if (currentScroll >= (yearWidth * index) * 0.75) yearInView = index
		});
	}

	// scroll to 1 year behind the current "yearInView"
	function goPrevious() {
		const element = document.getElementById('timeline-body')
		const yearElement = document.getElementById(years[yearInView - 1].year)
		yearElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
		// if (element != null && locale == 'ar') element.scrollLeft = -(yearWidth * (yearInView - 1))
		// else if (element != null) element.scrollLeft = (yearWidth * (yearInView - 1))
	}

	// scroll to 1 year ahead the current "yearInView"
	function goNext() {
		const element = document.getElementById('timeline-body')
		const yearElement = document.getElementById(years[yearInView + 1].year)
		yearElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
		// if (element != null && locale == 'ar') element.scrollLeft = -(yearWidth * (yearInView + 1))
		// else if (element != null) element.scrollLeft = (yearWidth * (yearInView + 1))
	}

	// scroll the timeline so that today is centered in view
	function goToday() {
		const element = document.getElementById('timeline-body')
		const currentYearIndex = years.map(e => parseInt(e.year, 10)).indexOf(currentYear);
		let width

		let newElement = document.getElementById(currentYear + '-bg')
		if (newElement != null) {
			let	style = window.getComputedStyle(newElement)
			width = style.getPropertyValue('width')
			width = width.split('px')[0]
		}

		if (element != null && locale == 'ar') element.scrollLeft = -(yearWidth * (currentYearIndex)) - (width - (yearWidth / 2))
		else if (element != null) element.scrollLeft = (yearWidth * (currentYearIndex)) + (width - (yearWidth / 2))
	}

	function hideOpen(milestone) {
		const element = document.getElementById(activeMilestone)
		if (milestone.id != activeMilestone) element.classList.remove('open')
	}

	function showOpen(milestone) {
		const element = document.getElementById(activeMilestone)
		if (milestone.id != activeMilestone) element.classList.add('open')
	}

	function translateMonth(month) {
		if (locale == 'ar') {
			const monthIndex = months.indexOf(month)
			const translatedMonth = monthsAR[monthIndex]
			return translatedMonth
		} else {
			return month.slice(0, 3)
		}
	}
</script>

<div class="page">
	<!-- Actual Diagram -->
	<div class="timeline-body" id="timeline-body" on:scroll={handleScroll} class:ar={locale == 'ar'}>
		{#each years as year, index}
			<div class="year" id="{year.year}">
				<div class="year-text" class:ar={locale == 'ar'}>{year.year}</div>
				<div class="months-wrapper" class:ar={locale == 'ar'}>
					{#each months as month}
						<div class="month" class:ar={locale == 'ar'}>
							<div class="milestones" class:ar={locale == 'ar'}>
								{#each year.eventList as milestone}
									{#if milestone.month == month}
										<div class="milestone-circle"
											class:complete={isMilestoneComplete(milestone, year.year)}
											class:target={isMilestoneOpen(milestone)}
											class:open={isMilestoneOpen(milestone)}
											id="{milestone.id}"
											on:mouseenter="{hideOpen(milestone)}"
											on:mouseleave="{showOpen(milestone)}"
											>
											<svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="14" viewBox="0 0 16.001 14"><defs><style>.milestone-check{fill:#fff;}</style></defs><path class="milestone-check" d="M18.681,5.232a.824.824,0,0,0-1.252.128L9.051,16.67l-4.668-3.5a.829.829,0,0,0-1.233.277A1.078,1.078,0,0,0,3.4,14.833l5.333,4A.825.825,0,0,0,9.221,19a.855.855,0,0,0,.684-.36l8.889-12a1.089,1.089,0,0,0-.114-1.408Z" transform="translate(-2.999 -5)"/></svg>
											<div class="milestone-text">
												<h6>
													{#if milestone.day && milestone.day != null && milestone.day != undefined}
													{milestone.day} 
													{/if}
													{translateMonth(milestone.month)}</h6>
												<p>{milestone.name}</p>
											</div>
										</div>
									{/if}
								{/each}
							</div>
							<p class="month-label" class:ar={locale == 'ar'}>{translateMonth(month)}</p>
						</div>
					{/each}
				</div>
				<div class="year-bg" id="{year.year}-bg" class:first={index == 0} class:ar={locale == 'ar'} style="width: {bgFillWidth(year)}"></div>
			</div>
		{/each}
	</div>

	<!-- Previous and Next -->

	<div class="timeline-footer" class:ar={locale == 'ar'}>
		{#if yearInView > 0}
				<button class="btn btn-tertiary prev" class:ar={locale == 'ar'} on:click="{goPrevious}">
					{#if locale == 'ar'}
						{years[yearInView - 1].year}
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><defs><style>.a{fill:#008d96;}</style></defs><path class="a" d="M21,11H5.661l6-5.248a1,1,0,1,0-1.317-1.505l-8,7c-.008.007-.011.018-.019.025a.962.962,0,0,0-.174.235.761.761,0,0,0-.061.091.97.97,0,0,0,0,.8c.015.034.043.059.061.091a.989.989,0,0,0,.174.235c.008.007.011.018.019.025l8,7a1,1,0,1,0,1.318-1.5L5.661,13H21a1,1,0,0,0,0-2Z" transform="translate(-2 -4)"/></svg>
					{:else}
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><defs><style>.a{fill:#008d96;}</style></defs><path class="a" d="M21,11H5.661l6-5.248a1,1,0,1,0-1.317-1.505l-8,7c-.008.007-.011.018-.019.025a.962.962,0,0,0-.174.235.761.761,0,0,0-.061.091.97.97,0,0,0,0,.8c.015.034.043.059.061.091a.989.989,0,0,0,.174.235c.008.007.011.018.019.025l8,7a1,1,0,1,0,1.318-1.5L5.661,13H21a1,1,0,0,0,0-2Z" transform="translate(-2 -4)"/></svg>
						{years[yearInView - 1].year}
					{/if}
				</button>
		{:else}
			<div></div>
		{/if}
		{#if yearInView + 1 < years.length}
				<button class="btn btn-tertiary next" class:ar={locale == 'ar'} on:click="{goNext}">
					{#if locale == 'ar'}
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><defs><style>.a{fill:#008d96;}</style></defs><path class="a" d="M21,11H5.661l6-5.248a1,1,0,1,0-1.317-1.505l-8,7c-.008.007-.011.018-.019.025a.962.962,0,0,0-.174.235.761.761,0,0,0-.061.091.97.97,0,0,0,0,.8c.015.034.043.059.061.091a.989.989,0,0,0,.174.235c.008.007.011.018.019.025l8,7a1,1,0,1,0,1.318-1.5L5.661,13H21a1,1,0,0,0,0-2Z" transform="translate(-2 -4)"/></svg>
						{years[yearInView + 1].year}
					{:else}
						{years[yearInView + 1].year}
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><defs><style>.a{fill:#008d96;}</style></defs><path class="a" d="M21,11H5.661l6-5.248a1,1,0,1,0-1.317-1.505l-8,7c-.008.007-.011.018-.019.025a.962.962,0,0,0-.174.235.761.761,0,0,0-.061.091.97.97,0,0,0,0,.8c.015.034.043.059.061.091a.989.989,0,0,0,.174.235c.008.007.011.018.019.025l8,7a1,1,0,1,0,1.318-1.5L5.661,13H21a1,1,0,0,0,0-2Z" transform="translate(-2 -4)"/></svg>
					{/if}
				</button>
		{:else}
			<div></div>
		{/if}
	</div>

</div>

<style>
	:root {
		/* --accent-primary: #84bd00; */
		--accent-primary: #84bd00;
		/* --accent-secondary: #008d96;
		--ui-0: #f5fbfc;
		--ui-1: #deeeef;
		--ui-2: #c7dee0;
		--ui-3: #9ab9bb;
		--ui-4: #749698;
		--text-regular: #2b2b2b;
		--text-medium: #595959;
		--text-light: #bebebe;
		--indication-info: #1db6c9;
		--indication-success: #1db96f;
		--indication-warning: #f29e47;
		--indication-danger: #dc373f; */
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		/* font-family: 'Dubai', sans-serif;
		color: #2b2b2b; */
	}

	.page {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.timeline-footer, .year-text {
		width: 100%;
		max-width: 80%;
		margin: 0 auto;
	}

	.timeline-footer {
		display: flex;
		justify-content: space-between;
		padding: .5rem 0;
	}

	.year-text {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 12rem;
		line-height: 1.5;
		font-weight: bold;
		color: #deeeef;
		/* border-left: .15rem solid #deeeef; */
	}

	.timeline-body {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		overflow: auto;
		scroll-snap-type: mandatory;
		padding: 0 10% 1rem;
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.timeline-body::-webkit-scrollbar {
		display: none;
	}

	.timeline-body {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	.year {
		width: 100%;
		height: 50vh;
		flex-shrink: 0;
		padding-bottom: 1.5rem;
		scroll-snap-align: start;
		position: relative;
	}

	.year-bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 65%;
		background-color: #f5fbfc;
		z-index: -1;
	}

	.year-bg.first {
		border-radius: 1.5rem 0rem 0rem 1.5rem;
	}

	.year-bg.first.ar {
		border-radius: 0rem 1.5rem 1.5rem 0rem;
	}

	.months-wrapper {
		position: absolute;
		bottom: 1.5rem;
		left: 0;
		width: 100%;
		display: flex;
	}

	.month {
		position: relative;
		flex-basis: 100%;
		height: 3.75rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		border-top: .5rem solid #deeeef;
		border-left: .15rem solid #deeeef;
	}

	.month-label {
		position: absolute;
		bottom: 0;
		left: .25rem;
		width: 100%;
		font-size: .875rem;
		text-transform: uppercase;
		color: #9ab9bb;
	}

	.milestones {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		position: absolute;
		top: -1.25rem;
		left: 0;
		z-index: 1;
	}

	.milestone-circle {
		position: relative;
		min-width: 2rem;
		min-height: 2rem;
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		border: .25rem solid white;
		background-color: #749698;
		box-shadow: 0 .25rem 1rem #008D9624;
		transition: all .2s ease-in-out;
	}

	.milestone-circle svg {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 0;
		transform: translate(-50%, -50%);
		transition: all .2s ease-in-out;
	}

	.milestone-circle.complete {
		background-color: #1db96f;
	}

	.milestone-circle.complete svg {
		height: .75rem;
		transition: all .2s ease-in-out;
	}

	.milestone-circle.complete:hover svg {
		height: .5rem;
		transition: all .2s ease-in-out;
	}

	.milestone-circle.complete .milestone-text {
		background-color: #1db96f;
	}

	.milestone-circle:hover {
		cursor: pointer;
		border-width: .5rem;
		transition: all .2s ease-in-out;
	}

	.milestone-circle:hover .milestone-text {
		opacity: 1;
		transition: all .2s ease-in-out;
	}

	.milestone-circle.target .milestone-text {
		top: -.5rem;
	}

	.milestone-circle.target svg {
		height: 0;
	}

	.milestone-circle.target:hover svg {
		height: 0;
	}

	.milestone-circle.target {
		position: relative;
		background-color: transparent;
		border-radius: 0;
		width: 0; 
		height: 0; 
		border-top: 0;
		border-left: 1.25rem solid transparent;
		border-right: 1.25rem solid transparent;
		border-bottom: 2rem solid #fff;
		transform: translateY(-.125rem);
		box-shadow: none;
		filter: drop-shadow(0 .25rem 1rem #008D9624)
	}

	.milestone-circle.complete.target::before {
		border-bottom: 1.5rem solid #1db96f;
	}

	.milestone-circle.complete.target:hover::before {
		border-bottom: 1rem solid #1db96f;
	}

	.milestone-circle.complete.target .milestone-text {
		background: linear-gradient(315deg,#008d96 0,#84bd00 100%) 0 0 no-repeat padding-box;
	}

	.milestone-circle.complete.target svg {
		height: .675rem;
		top: 1.3rem;
		left: -.1rem;
	}

	.milestone-circle.complete.target:hover svg {
		height: .45rem;
		top: 1.25rem;
		left: -.07rem;
	}

	.milestone-circle.target::before {
		content: "";
		position: absolute;
		top: .325rem;
		left: -.875rem;
		background-color: transparent;
		border-radius: 0;
		width: 0; 
		height: 0; 
		border-left: .875rem solid transparent;
		border-right: .875rem solid transparent;
		border-bottom: 1.5rem solid #749698;
		transition: all .2s ease-in-out;
	}

	.milestone-circle.target:hover::before {
		content: "";
		position: absolute;
		top: .585rem;
		left: -.575rem;
		background-color: transparent;
		border-radius: 0;
		width: 0; 
		height: 0; 
		border-left: .575rem solid transparent;
		border-right: .575rem solid transparent;
		border-bottom: 1rem solid #749698;
		transition: all .2s ease-in-out;
	}

	.milestone-circle.open .milestone-text {
		opacity: 1;
		transition: all .4s ease-in-out;
	}

	.milestone-text {
		pointer-events: none;
		position: absolute;
		top: -100%;
		left: 50%;
		transform: translate(-50%, -100%);
		width: 12rem;
		min-height: 10rem;
		border-radius: .75rem;
		padding: 1rem;
		text-align: center;
		background-color: #749698;
		box-shadow: 0 .25rem 1rem #008D9624;
		opacity: 0;
		transition: all .2s ease-in-out;
	}

	.milestone-text h6 {
		font-size: 1.5rem;
		color: #fff;
		margin: 0;
		text-transform: uppercase;
	}

	.milestone-text p {
		color: #fff;
		margin: 0;
	}

	.btn svg {
		width: 1em;
	}

	.btn.prev svg {
		margin-right: .75rem;
	}

	.btn.next svg {
		margin-left: .75rem;
		transform: rotate(180deg);
	}

	/* ARABIC STYLES BEGIN */

	.timeline-body.ar {
		flex-direction: row-reverse;
	}

	.year-text.ar {
		left: auto;
		right: 0;
		text-align: right;
	}

	.year-bg.ar {
		left: auto;
		right: 0;
	}

	.months-wrapper.ar {
		flex-direction: row-reverse;
	}

	.month.ar {
		flex-direction: row-reverse;
		border-left: none;
		border-right: .15rem solid #deeeef;
	}

	.month-label.ar {
		left: auto;
		right: .25rem;
		text-align: right;
	}

	.timeline-footer.ar {
		flex-direction: row-reverse;
	}

	.btn.prev.ar svg {
		margin-right: auto;
		margin-left: .75rem;
		transform: rotate(180deg);
	}

	.btn.next.ar svg {
		margin-left: auto;
		margin-right: .75rem;
		transform: rotate(0deg);
	}

	.prev.ar, .next.ar {
		flex-direction: row-reverse;
	}
	
	/* END */

	@media (max-width: 768px) {
		.year {
			height: 45vh;
		}

		.year-text {
			font-size: 9rem;
			line-height: 1.5;
		}

		.month {
			border-left: .15rem solid transparent;
		}

		.month.ar {
			border-right: .15rem solid transparent;
		}

		.month-label {
			opacity: 0;
		}

		.month:first-of-type {
			border-left: .15rem solid #deeeef;
		}

		.month.ar:first-of-type {
			border-left: none;
			border-right: .15rem solid #deeeef;
		}

		.month:first-of-type .month-label {
			opacity: 1;
		}

		.milestones {
			top: -1rem;
		}

		.milestone-circle {
			min-width: 1.25rem;
			min-height: 1.25rem;
			width: 1.25rem;
			height: 1.25rem;
			/* border: none; */
		}

		.milestone-circle.complete svg {
			height: 0;
		}

		.milestone-circle.complete:hover svg {
			height: 0;
		}

		.milestone-circle.complete.target svg {
			height: 0;
		}

		.milestone-circle.complete.target:hover svg {
			height: 0;
		}

		.milestone-text {
			top: -200%;
		}

		.milestone-circle.target .milestone-text {
			top: -.125rem;
		}

		.milestone-circle.target {
			border-left: .75rem solid transparent;
			border-right: .75rem solid transparent;
			border-bottom: 1.125rem solid #fff;
			transform: translateY(0rem);
		}

		.milestone-circle.complete.target::before {
			border-bottom: .675rem solid #1db96f;
		}

		.milestone-circle.complete.target:hover::before {
			border-bottom: .375rem solid #1db96f;
		}

		.milestone-circle.target::before {
			top: .425rem;
			left: -.5rem;
			border-left: .5rem solid transparent;
			border-right: .5rem solid transparent;
			border-bottom: .675rem solid #749698;
			transition: all .2s ease-in-out;
		}

		.milestone-circle.target:hover::before {
			top: .575rem;
			left: -.25rem;
			border-left: .25rem solid transparent;
			border-right: .25rem solid transparent;
			border-bottom: .375rem solid #749698;
			transition: all .2s ease-in-out;
		}
	}

	@media (max-width: 560px) {
		.year-text {
			font-size: 6rem;
		}

		.milestones {
			top: -.65rem;
		}

		.milestone-circle {
			min-width: .75rem;
			min-height: .75rem;
			width: .75rem;
			height: .75rem;
			border: none;
		}

		.milestone-text {
			top: -80%;
		}

		.milestone-circle.target .milestone-text {
			top: -.125rem;
		}

		.milestone-circle.target {
			border-left: .75rem solid transparent;
			border-right: .75rem solid transparent;
			border-bottom: 1.125rem solid transparent;
			transform: translateY(-.425rem);
		}

		.milestone-circle.complete.target::before {
			border-bottom: .675rem solid #1db96f;
		}

		.milestone-circle.complete.target:hover::before {
			border-bottom: .675rem solid #1db96f;
		}

		.milestone-circle.target::before {
			top: .425rem;
			left: -.5rem;
			border-left: .5rem solid transparent;
			border-right: .5rem solid transparent;
			border-bottom: .675rem solid #749698;
			transition: all .2s ease-in-out;
		}

		.milestone-circle.target:hover::before {
			top: .425rem;
			left: -.5rem;
			border-left: .5rem solid transparent;
			border-right: .5rem solid transparent;
			border-bottom: .675rem solid #749698;
			transition: all .2s ease-in-out;
		}
	}
</style>